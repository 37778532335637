
const Extension = () => {

  return (
    <div className="flex column" style={{width:"100%", height:"100%", flex:1, alignItems:'center'}}>
      <div className="flex row roomheader" style={{alignItems:'center', justifyContent:'center'}}>
        <div className="flex row" style={{flex:1, justifyContent:"flex-start", alignItems:'center', marginLeft:20}}>
          <a href="/" className="flex row" style={{color:"white", alignItems:'center', textDecoration:'none', justifySelf:'flex-start'}}><img src="/dr128.png" alt="site logo" style={{height:30, width:30}}/><span className={"menulabel"}>Dice Relay</span></a>
        </div>
        <div className="flex row" style={{flex:1, justifyContent:"flex-end", alignItems:'center', marginRight:20}}>
          <a href="/extension" className="flex row" style={{color:"white", alignItems:'center', textDecoration:'none', justifySelf:'flex-end'}}><span className={"menulabel"}>Chrome Extension</span></a>
        </div>
      </div>
      <div className="flex column" style={{width:"100%", minHeight:100, flex:1, flexShrink:0, alignItems:'center'}}>
        <div className="flex column"  style={{height:"100%", width:"100%", overflowY:'auto', alignItems:'center'}}>
          <div className="flex column" style={{maxWidth:650}}>
            <div className="flex column" style={{marginBottom:20, marginTop:40, alignItems:'flex-start'}}>

              <span className="flex row"style={{fontSize:"2em", fontWeight:'bold', marginRight:10, flexShrink:0}}>Install the Extension</span>
              <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10, width:"100%"}} >
                <span style={{fontWeight:"bold"}}>Step 1</span>
                <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
                  <p>Click the button to install the Dice Relay extension for chrome.</p>

                  <div className="flex row" style={{width:"100%", marginTop:10, justifyContent:'flex-start', alignItems:'center'}} >
                    <a
                        className="btn flex row"
                        style={{padding:10, flexShrink:0, borderRadius:10, alignItems:'center', textDecoration:'none'}}
                        href={"https://chrome.google.com/webstore/detail/dice-relay/bjgobcfbjhhlpagdhliedbppeagedomo"}
                        target="_blank" rel="noopener noreferrer"
                      >{"Dice Relay Extension"}<img src="/dr128.png" alt="site logo" style={{height:40, width:40, marginLeft:20}}/></a>
                  </div>
                </div>
              </div>

              <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10}} >
                <span style={{fontWeight:"bold"}}>Step 2 (optional)</span>
                <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
                  <p>You can pin the extension to make it easier to enable on different sites.</p>
                  <p>Click the chrome extension button (the puzzle piece icon to the right of the location bar). You should see the Dice Relay extension listed. Click the pin icon.</p>
                  <p>Now you can easily enable or disable the extension for any website.</p>
                </div>
              </div>

              <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10}} >
                <span style={{fontWeight:"bold"}}>Step 3</span>
                <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
                  <p>Open a tab on dndbeyond.com and enable the extension.</p>
                  <p>Enable the extension on dicerealy.com and create or join a room to start getting dice rolls.</p>
                  <p>If you are a website or Virtual Tabletop Developer and would like to integrate with this extension, you can reach out to <a style={{color:"#fff"}} href="mailto:dicerelay@gmail.com">dicerelay@gmail.com</a></p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Extension;
