import {useState} from 'react';
import {useHistory} from "react-router-dom";

import NoConnection from "../components/NoConnection";

function rChar() {
  const rval = Math.floor(Math.random() * 36);

  if(rval < 10) {
    return String.fromCharCode(rval + 48)
  }
  else {
    return String.fromCharCode(rval - 10 + 65);
  }
}

function createRandomCode() {
  let code = "";
  while(code.length < 6) {
    code = code + rChar();
  }
  return code;
}

const Home = () => {
  const [roomCode, setRoomCode] = useState("");

  const history = useHistory();

  const onEnterRoom = () => {
    if(roomCode.trim().length === 0) {
      window.alert("You must enter a room code.");
      return;
    }
    history.push("/r/"+roomCode);
  };

  const onCreateRoom = () => {
    const rc = createRandomCode();
    history.push("/r/"+rc);
  };

  return (
    <div className="flex column" style={{width:"100%", height:"100%", flex:1, alignItems:'center'}}>
      <div className="flex row roomheader" style={{alignItems:'center', justifyContent:'center'}}>
        <div className="flex row" style={{flex:1, justifyContent:"flex-start", alignItems:'center', marginLeft:20}}>
          <a href="/" className="flex row" style={{color:"white", alignItems:'center', textDecoration:'none', justifySelf:'flex-start'}}><img src="/dr128.png" alt="site logo" style={{height:30, width:30}}/><span className={"menulabel"}>Dice Relay</span></a>
        </div>
        <div className="flex row" style={{flex:1, justifyContent:"flex-end", alignItems:'center', marginRight:20}}>
          <a href="/extension" className="flex row" style={{color:"white", alignItems:'center', textDecoration:'none', justifySelf:'flex-end'}}><span className={"menulabel"}>Chrome Extension</span></a>
        </div>
      </div>
      <div className="flex column" style={{width:"100%", minHeight:100, flex:1, flexShrink:0, marginLeft:"auto", marginRight:"auto", alignItems:'center'}}>
        <div className="flex column" style={{maxWidth:600}}>
          <h1>Dice Relay</h1>

          <p>Welcome to Dice Relay. This site allows you to send dice rolls from DNDBeyond to tabletop simulator.</p>

          <NoConnection />

          <div className="flex column" style={{marginBottom:20, marginTop:20}}>
            <div className="flex row" style={{alignItems:'center'}}>
              <span className="flex row"style={{fontSize:"1.5em", height:"100%", marginRight:10, flexShrink:0, alignItems:'center'}}>Room Code</span>
              <input className="flex row" style={{flex:1, padding:10, fontSize:"1.5em", border:'none', borderBottomLeftRadius:10, borderTopLeftRadius:10}} value={roomCode} onChange={(e) => {setRoomCode(e.target.value)}} />
              <button
                  className="btn flex row"
                  style={{padding:10, flexShrink:0, borderBottomRightRadius:10, borderTopRightRadius:10}}
                  onClick={onEnterRoom}
                >Enter Room</button>
            </div>
          </div>

          <h2>OR</h2>

          <div className="flex column" style={{marginBottom:20, marginTop:20}}>
            <div className="flex row">
              <button
                  className="btn flex row"
                  style={{padding:10, flex:1, justifyContent:'center', borderRadius:10}}
                  onClick={onCreateRoom}
                >Create Room</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
