import ExtensionService from "./extension";

class RoomApi {
  constructor() {
    this.roomSubscribers = [];
    this.roomLog = [];
    this.currentRoom = null;
    this.eventSource = null;

    ExtensionService.subscribe(this.handleRoomPubMessage);
  }

  disconnectRoom = () => {
    this.roomSubscribers.forEach((s) => {
      try{
        s.unsubscribe();
      }
      catch(e) {
        console.log("Error unsubscribing room listener.", e);
      }
    });

    if(this.eventSource) {
      this.eventSource.close();
    }

    this.roomLog = [];
    this.currentRoom = null;
    this.eventSource = null;
    this.extSub = null;
  }

  connectRoom = (roomCode) => {
    if(this.currentRoom) {
      if(this.currentRoom === roomCode) {
        return;
      }
      else {
        this.disconnectRoom();
      }
    }

    this.eventSource = new EventSource("https://dicerelay.com/relaysub/"+roomCode);
    this.eventSource.onmessage = this.handleRoomSubMessage;
    this.currentRoom = roomCode;
  }

  handleRoomPubMessage = (message) => {
    if(this.currentRoom) {
      //console.log("Connected room recieved message: "+JSON.stringify(message));

      fetch('https://dicerelay.com/relaypub/'+this.currentRoom, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message)
      })
    }
    else {
      console.log("No room connected, but recieved message: "+JSON.stringify(message));
    }
  }

  handleRoomSubMessage = (event) => {
    if(!event.data) {
      console.log("Received sub event with no data in its message.");
    }
    //console.log("Recieved sub event: "+JSON.stringify(event.data));
    this.roomLog = this.roomLog.slice(-99);
    this.roomLog.push(event.data);

    this.roomSubscribers.forEach((rs) => {
      try {
        if(rs.onEventFunc) {
          rs.onEventFunc(event.data);
        }
      }
      catch(e) {
        console.log("Error in event handler of room subscriber.", e);
      }
    });
  }

  getRoomLog = () => {
    return this.roomLog;
  }

  subscribe = (onEventFunc) => {
    const unsubFunc = this.unsubscribe;

    const sub = {
      onEventFunc: onEventFunc,
    }

    sub.unsubscribe = () => {
      unsubFunc(sub);
    }

    this.roomSubscribers.push(sub);
    return sub;
  }

  unsubscribe = (sub) => {
    this.roomSubscribers = this.roomSubscribers.filter((rs) => rs !== sub);
  }


}

const RoomService = new RoomApi();
export default RoomService;
