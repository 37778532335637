class TTSScriptApi {
  constructor() {
    this.scriptContentsPromise = null;
    this.scriptContents = null;
  }

  getScriptContents = () => {
    if(this.scriptContents) {
      return Promise.resolve(this.scriptContents);
    }
    else if(this.scriptContentsPromise) {
      return this.scriptContentsPromise;
    }
    else {
      const thisService = this;

      this.scriptContentsPromise = new Promise((resolve, reject) => {
        fetch("/tts-script.ttslua").then((result) => {
          if(result.ok) {
            thisService.scriptContents = result.text();
            resolve(thisService.scriptContents);
          }
          else {
            reject("Couldn't get script.");
            this.scriptContentsPromise = null;
          }
        }).catch((e) => {
          console.log("Error getting lua script.", e);
          reject("Couldn't get script.")
          this.scriptContentsPromise = null;
        });
      })

      return this.scriptContentsPromise;
    }
  }

}

const TTSScriptService = new TTSScriptApi();
export default TTSScriptService;
