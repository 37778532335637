import { useEffect, useRef } from "react";

const GameLog = ({messages}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    window.setTimeout(() => {
      if(containerRef.current) {
        const lastChild = containerRef.current.childNodes[containerRef.current.childNodes.length-1];
        if(lastChild) {
          lastChild.scrollIntoView({behavior:'smooth'});
        }
      }
    }, 200);
  }, [messages]);

  const renderDiceRoll = (m, ind) => {
    return (
      <div key={ind} className="flex row" style={{ borderRadius:5, borderWidth:1, borderStyle:'solid', borderColor:'#aaa', padding:10, alignItems:'stretch', margin:"0 0 8px 0", backgroundColor:"#000"}}>
        <div className="flex column" style={{alignItems:'flex-end', justifyContent:'flex-start', width:"25%", paddingRight:10}}>
          <span style={{color:"#fff", fontweight:'bold', fontSize:'1em'}}>{m.character}</span>
        </div>
        <div className="flex column" style={{padding:0, margin:0, width:1, backgroundColor:"#aaa"}} />
        <div className="flex column" style={{alignItems:'flex-start', justifyContent:'flex-start', flex:1, flexShrink:0, paddingLeft:10}}>
          <span style={{color:"#fff", fontSize:'1em', fontWeight:'bold'}}>{m.rollDesc}</span>
          <span style={{color:"#fff", fontSize:'0.8em'}}>Roll: {m.diceDesc}</span>
          <span style={{color:"#fff", fontSize:'0.8em'}}>Result: {m.diceResultDetailed} = <span style={{fontSize:'1.25em', fontWeight:'bold'}}>{m.diceResultTotal}</span></span>
        </div>
      </div>
    );
  }

  const msgs = messages.map((m, ind) => {
    let json = null;
    try{
      json = JSON.parse(m);
    }
    catch(e) {
      console.log("Error parsing JSON.", e);
      json = m;
    }

    if(json.messageType === "dice-roll") {
      return renderDiceRoll(json, ind);
    }
    else {
      return (
        <div key={ind}>
          {JSON.stringify(json)}
        </div>
      );
    }
  })

  return (
    <div className="flex column" style={{height:"100%", alignItems:'center'}}>
      <h1 className="flex row" style={{alignSelf:'center'}}>Game Log</h1>
      <div className="flex column"
          style={{flex:1, minHeight:0, overflowY:'auto', overflowX:'hidden', maxWidth:800, width:650, minWidth:400, alignItems:'stretch'}}
          ref={containerRef}>
        {msgs}
        {msgs.length === 0 ?
          <p>Open a tab on <a style={{color:"#fff"}} href="https://www.dndbeyond.com" target="_blank" rel="noopener noreferrer">dndbeyond.com</a> and roll some dice.</p>
          : null
        }
      </div>
    </div>
  );
}

export default GameLog;
