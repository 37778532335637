import { useState, useEffect } from "react";

import ExtensionService from "../api/extension";

const NoConnection = () => {
  const [extensionConnected,setExtensionConnected] = useState(false);

  useEffect(() => {
    const connectionSub = ExtensionService.subscribeToConnection(() => {
      console.log("Connected to dice relay extension.")
      setExtensionConnected(true);
    })

    if(ExtensionService.isConnected()) {
      setExtensionConnected(true);
    }

    return () => {
      connectionSub.unsubscribe();
    }
  }, []);

  if(extensionConnected !== true) {
    return(
      <div className="flex column" style={{padding:10, borderWidth:2, borderRadius:10, borderColor:"#A44", borderStyle:'solid'}}>
        <p style={{color:"#F44"}}>This website requires a browser extension to work correctly.</p>
        <p style={{color:"#F44", marginTop:10}}>If you already have the DiceRelay extension, you may need to enable it for this site.</p>
        <p style={{marginTop:10}}><a style={{color:"#fff"}} href="/extension">Get the DiceRelay Extension.</a></p>
      </div>
    );
  }
  else {
    return null;
  }
}

export default NoConnection;
