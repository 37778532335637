import { useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import NoConnection from "../components/NoConnection";
import GameLog from "../components/GameLog";
import GameInfo from "../components/GameInfo";
import RoomService from "../api/room";

const GameRoom = () => {
  const { roomCode } = useParams();

  const [viewIndex, setViewIndex] = useState(0);
  const [logMessages, setLogMessages] = useState([]);

  useEffect(() => {
    RoomService.connectRoom(roomCode);

    RoomService.subscribe(handleRoomLogMessage);

    return () => {
      RoomService.disconnectRoom();
    }
  }, [roomCode]);

  const handleRoomLogMessage = (evt) => {
    setLogMessages(RoomService.getRoomLog());
  }

  const renderContent = () => {
    if(viewIndex === 0) {
      return <GameLog messages={logMessages} />
    }
    else {
      return <GameInfo roomCode={roomCode} />
    }
  }

  return (
    <div className="flex column" style={{width:"100%", height:"100%", flex:1, alignItems:'center'}}>
      <div className="flex row roomheader" style={{alignItems:'center', justifyContent:'center'}}>
        <div className="flex row" style={{flex:1, justifyContent:"flex-start", alignItems:'center', marginLeft:20}}>
          <a href="/" className="flex row" style={{color:"white", alignItems:'center', textDecoration:'none', justifySelf:'flex-start'}}><img src="/dr128.png" alt="site logo" style={{height:30, width:30}}/><span className={"menulabel"}>Dice Relay</span></a>
        </div>
        <span className={"menulabel"}>Room: {roomCode}</span>
        <div><button className={"menuitem "+(viewIndex===0 ? "selected":"")} onClick={() => setViewIndex(0)}>Game Log</button></div>
        <div><button className={"menuitem "+(viewIndex===1 ? "selected":"")} onClick={() => setViewIndex(1)}>Game Info</button></div>
        <div className="flex row" style={{flex:1, justifyContent:"flex-end", alignItems:'center', marginRight:20}}>
        </div>
      </div>
      <div className="flex column" style={{width:"100%", minHeight:100, flex:1, flexShrink:0, marginLeft:"auto", marginRight:"auto"}}>
        <NoConnection />
        {renderContent()}
      </div>
    </div>
  )
}

export default GameRoom;
