

class ExtensionApi {
  constructor() {
    this.listening = false;
    this.connected = false;

    this.connectionSubscribers = [];
    this.rollSubscribers = [];
  }

  startListening = () => {
    console.log("Dice Relay: Starting to listen to message events.");
    const ext = this;

    ext.connectListener();

    window.setTimeout(() => {
      if(!ext.connected) {
        ext.connectListener();
      }
    }, 2000);
  }

  connectListener = () => {
    const ext = this;

    if(ext.listening) {
      this.sendConnectionMessage();
      return;
    }


    window.addEventListener('message', function(event) {
      const data = event.data;

      if(typeof(data) === "string") {
        if(data === "dice-relay-registration-request") {
          console.log("DiceRelay extension requested connection. Attempting to connect.");
          ext.sendConnectionMessage();
        }
        else if(data === "dice-relay-registration-success") {
          console.log("Connected to DiceRelay extension.");
          ext.connected = true;

          ext.connectionSubscribers.forEach((sub) => {
            try {
              if(sub.onEventFunc) {
                sub.onEventFunc(data);
              }
            }
            catch(e) {
              console.log("Dice roll connection subscriber handler had error.", e);
            }
          });
        }
      }
      else {
        if(data.messageType && data.messageType === "dice-roll") {
          console.log("Dice Relay: Got dice roll message: "+JSON.stringify(data));

          ext.rollSubscribers.forEach((sub) => {
            try {
              if(sub.onEventFunc) {
                sub.onEventFunc(data);
              }
            }
            catch(e) {
              console.log("Dice roll subscriber handler had error.", e);
            }
          });
        }
      }
    });

    ext.listening = true;

    //Send a connection message
    ext.sendConnectionMessage();

  }

  sendConnectionMessage = () => {
    window.postMessage({
      messageType: "dice-relay-registration",
      name: "Dice Relay",
      description: "Relays dice rolls from dndbeyond.",
      ready: true
    });
  }

  isConnected = () => {
    return this.connected;
  }

  subscribe = (onEventFunc) => {
    const unsubFunc = this.unsubscribe;

    const sub = {
      onEventFunc: onEventFunc,
    }

    sub.unsubscribe = () => {
      unsubFunc(sub);
    }

    this.rollSubscribers.push(sub);
    return sub;
  }

  unsubscribe = (sub) => {
    this.rollSubscribers = this.rollSubscribers.filter((rs) => rs !== sub);
  }

  subscribeToConnection = (onEventFunc) => {
    const unsubFunc = this.unsubscribeFromConnection;

    const sub = {
      onEventFunc: onEventFunc,
    }

    sub.unsubscribe = () => {
      unsubFunc(sub);
    }

    this.connectionSubscribers.push(sub);
    return sub;
  }

  unsubscribeFromConnection = (sub) => {
    this.connectionSubscribers = this.connectionSubscribers.filter((rs) => rs !== sub);
  }

}

const ExtensionService = new ExtensionApi();
export default ExtensionService;
