import { useState, useRef } from "react";

import TTSScriptService from "../api/tts-script";


const GameInfo = ({roomCode}) => {
  const [copiedElm, setCopiedElm] = useState("");
  const urlInputRef = useRef(null);
  const codeInputRef = useRef(null);
  const scriptRef = useRef(null);

  const [luaScriptContents, setLuaScriptContents] = useState("")

  const url = "https://dicerelay.com/r/"+roomCode;

  const copyText = (inputElm) => {
    if(inputElm) {
      inputElm.select();
      inputElm.setSelectionRange(0, 99999); /* For mobile devices */

      document.execCommand("copy");

      return true;
    }

    return false;
  }

  const copyURL = (e) => {
    e.preventDefault();
    setCopiedElm("");

    if(copyText(urlInputRef.current)) {
      setCopiedElm("url");
    }
  }

  const copyCode = (e) => {
    e.preventDefault();
    setCopiedElm("");

    if(copyText(codeInputRef.current)) {
      setCopiedElm("code");
    }
  }

  const copyScript = async (e) => {
    e.preventDefault();
    setCopiedElm("");

    try {
      let scriptText = luaScriptContents;
      if(scriptText.length === 0) {
        scriptText = await TTSScriptService.getScriptContents();
        setLuaScriptContents(scriptText);
      }

      if(scriptRef.current) {
        scriptRef.current.value = scriptText;

        if(copyText(scriptRef.current)) {
          setCopiedElm("script");
        }
      }
    }
    catch(e) {
      console.error("Error loading TTS Script.", e);
      window.alert("There was an error loading the script. Please try again.");
    }

  }

  return(
    <div className="flex column"  style={{height:"100%", width:"100%", overflowY:'auto', alignItems:'center'}}>
      <div className="flex column" style={{maxWidth:650}}>
        <div className="flex column" style={{marginBottom:20, marginTop:20, alignItems:'flex-start'}}>

          <span className="flex row"style={{fontSize:"2em", fontWeight:'bold', marginRight:10, flexShrink:0}}>Invite To Room</span>
          <div className="flex row" style={{alignItems:'center', width:"100%"}} >
            <span style={{textAlign:'right', width:100, paddingRight:10}}>Room URL:</span>
            <input ref={urlInputRef} style={{border:'none', fontWeight:'bold', paddingLeft:10, paddingRight:10, height:"100%", flex:1, backgroundColor:"#ccc"}} readOnly value={url} />
            <button
                className="btn flex row"
                style={{padding:10, flexShrink:0, borderBottomRightRadius:10, borderTopRightRadius:10, border:'none', width:150}}
                onClick={copyURL}
              >Copy URL</button>
            {copiedElm === "url" ?
              <span style={{color:"#aaa", paddingLeft:10}}>Copied</span>
              : null
            }
          </div>
          <p>OR</p>
          <div className="flex row" style={{alignItems:'center', width:"100%"}} >
            <span style={{textAlign:'right', width:100, paddingRight:10}}>Room Code:</span>
            <input ref={codeInputRef} style={{border:'none', fontWeight:'bold', paddingLeft:10, paddingRight:10, height:"100%", flex:1, backgroundColor:"#ccc"}} readOnly value={roomCode} />
            <button
                className="btn flex row"
                style={{padding:10, flexShrink:0, borderBottomRightRadius:10, borderTopRightRadius:10, border:'none', width:150}}
                onClick={copyCode}
              >Copy Code</button>
            {copiedElm === "code" ?
              <span style={{color:"#aaa", paddingLeft:10}}>Copied</span>
              : null
            }
          </div>

        </div>

        <div className="flex column" style={{marginBottom:20, marginTop:40, alignItems:'flex-start'}}>

          <span className="flex row"style={{fontSize:"2em", fontWeight:'bold', marginRight:10, flexShrink:0}}>Connect To Tabletop Simulator</span>
          <p>Dice Relay can send dice rolls to Tabletop Simulator's chat. This is currently a work in progress!</p>
          <p style={{color:"#FF8"}}>Note: For the following instructions to work properly, you must be the host of a multiplayer Tabletop Simulator server.</p>
          <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10, width:"100%"}} >
            <span style={{fontWeight:"bold"}}>Step 1</span>
            <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
              <p>Copy the following LUA script.</p>
              <textarea ref={scriptRef}
                  style={{border:'none', width:"100%", padding:10, height:100}}
                  readOnly
                  value={luaScriptContents}
                  placeholder="(Script will load the first time you push the copy button.)"
                />

              <div className="flex row" style={{width:"100%", marginTop:10, justifyContent:'flex-start', alignItems:'center'}} >
                <button
                    className="btn flex row"
                    style={{padding:10, flexShrink:0, borderRadius:10, border:'none'}}
                    onClick={copyScript}
                  >Copy Script</button>
                {copiedElm === "script" ?
                  <span style={{color:"#aaa", paddingLeft:10}}>Copied</span>
                  : null
                }
              </div>
            </div>
          </div>

          <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10}} >
            <span style={{fontWeight:"bold"}}>Step 2</span>
            <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
              <p>Select <span style={{fontWeight:'bold'}}>Modding {"->"} Scripting</span> from the top menu in Tabletop Simulator.</p>
              <p>Paste the copied script into the global script area and click the <span style={{fontWeight:'bold'}}>{"Save & Play"}</span> button.</p>
              <p style={{color:"#FF8"}}>Note: If you already have a global script, it's not recommended to replace it with this script. We are still working on Tabletop Simulator integration and will hopefully have something better soon.</p>
            </div>
          </div>

          <div className="flex column" style={{alignItems:'flex-start', marginLeft:20, marginBottom:10}} >
            <span style={{fontWeight:"bold"}}>Step 3</span>
            <div className="flex column" style={{marginLeft:40, alignItems:'flex-start', width:"100%"}}>
              <p>Open the <span style={{fontWeight:'bold'}}>Game</span> chat and type the following:</p>
              <input style={{border:'none', fontWeight:'bold', paddingLeft:10, paddingRight:10, width:"100%", backgroundColor:"#fff"}} readOnly value={"relay start "+roomCode} />
              <p>For a list of other commands, you can type: </p>
              <input style={{border:'none', fontWeight:'bold', paddingLeft:10, paddingRight:10, width:"100%", backgroundColor:"#fff"}} readOnly value={"relay"} />
            </div>
          </div>

        </div>
      </div>

    </div>
  );

}

export default GameInfo;
