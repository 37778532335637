import {
  Link
} from "react-router-dom";

const NoMatch = () => {
  return (
    <div>
      <p>Oops! This page doesn't exist.</p>
      <p><Link style={{color:"#fff"}} to="/">Go to the home page</Link></p>
    </div>
  )
}

export default NoMatch;
