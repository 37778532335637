import {useEffect, useState} from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ExtensionService from "./api/extension";

import Home from "./pages/Home"
import NoMatch from "./pages/NoMatch"
import GameRoom from "./pages/GameRoom"
import Extension from "./pages/Extension"


function App() {
  const [extensionConnected,setExtensionConnected] = useState(false);

  useEffect(() => {
    const connectionSub = ExtensionService.subscribeToConnection(() => {
      console.log("Connected to dice relay extension.")
      setExtensionConnected(true);
    })

    ExtensionService.startListening();

    return () => {
      connectionSub.unsubscribe();
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home extensionConnected={extensionConnected}/>
          </Route>
          <Route path="/r/:roomCode">
            <GameRoom extensionConnected={extensionConnected}/>
          </Route>
          <Route path="/extension">
            <Extension extensionConnected={extensionConnected}/>
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
